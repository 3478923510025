
<script setup>
    import { computed, reactive, provide } from "vue";

    const props = defineProps({
        modelValue: { type: String },
    });
    const emit = defineEmits(["update:modelValue"]);

    const model = computed({
        get() { return props.modelValue },
        set(val) { emit("update:modelValue", val) },
    });

    provide("tab", model);
</script>

<template>
    <div class="afm-tab-bar">
        <slot />
    </div>
</template>

<style scoped>
    .afm-tab-bar {
        display: flex;
        height: 3rem;

        border-top: 1px solid var(--bs-border-color);
    }
</style>
